<template>
  <div>
    <b-card v-if="room" class="room-card" :border-variant="isZoomStartView ? 'light' : ''" :bg-variant="isZoomStartView ? 'transparent' : 'white'">
      <component
        :is="currentComponent"
        :room="room"
        :initial-configuration="initialConfiguration"
        @join-meeting="handleJoinMeeting"
        @leave-meeting="handleLeaveMeeting"
        @edited="$emit('edited')"
      />
    </b-card>
    <b-card v-if="room.Meetings && room.Meetings.length" title="Agenda">
      <events-agenda :forced-events="room.Meetings" :centered="false" />
    </b-card>
  </div>
</template>

<script>
import EventsAgenda from "@/views/apps/events/components/EventsAgenda";

import { RoomProvider } from "@copernicsw/community-common";
import RoomWelcomeMeeting from "./RoomWelcomeMeeting.vue";
import RoomPreparationMeeting from "./RoomPreparationMeeting.vue";
import RoomStartMeeting from "./RoomStartMeeting.vue";
import RoomClosedMeeting from "./RoomClosedMeeting.vue";
import BlueJeansMeeting from "./BlueJeansMeeting.vue";
import ZoomMeeting from "./ZoomMeeting.vue";
import BigBlueButton from "./BigBlueButton.vue";

const MeetingSteps = Object.freeze({
  welcome: "meeting-step-welcome",
  preparation: "meeting-step-preparation",
  start: "meeting-step-start",
  closed: "meeting-step-close",
});

export default {
  name: "RoomDetails",
  components: {
    EventsAgenda,
    RoomWelcomeMeeting,
    RoomPreparationMeeting,
    RoomStartMeeting,
    RoomClosedMeeting,
  },
  props: {
    room: {
      type: Object,
      required: true,
    },
    skipWelcome: Boolean,
    skipWelcomePreparation: Boolean,
  },
  data() {
    return {
      currentStep: MeetingSteps.welcome,
      initialConfiguration: {
        isMicOn: true,
        isVideoOn: true,
      },
    };
  },
  computed: {
    meeting() {
      return MeetingSteps;
    },
    currentComponent() {
      switch (this.currentStep) {
        case MeetingSteps.welcome:
          return RoomWelcomeMeeting;
        case MeetingSteps.preparation:
          return this.roomApp;
        case MeetingSteps.start:
          return this.roomApp;
        case MeetingSteps.closed:
          return RoomClosedMeeting;
        default:
          // should never happen.
          return null;
      }
    },
    roomApp() {
      switch (this.room.app) {
        case RoomProvider.bluejeans:
          return BlueJeansMeeting;
        case RoomProvider.zoom:
          return ZoomMeeting;
        case RoomProvider.bbb:
        case RoomProvider.bbbUsingCommunityKey:
          return BigBlueButton;
        default:
          return RoomStartMeeting;
      }
    },
    isZoomStartView() {
      return this.room.app === 0 && this.currentStep === this.meeting.start;
    },
  },
  created() {
    this.updateBreadcrumbs();
  },
  mounted() {
    if (this.skipWelcome) {
      this.currentStep = MeetingSteps.preparation;
    }
    if (this.skipWelcomePreparation) {
      this.currentStep = MeetingSteps.start;
    }
  },
  methods: {
    handleJoinMeeting(config) {
      this.currentStep =
        this.currentStep === MeetingSteps.welcome
          ? MeetingSteps.preparation
          : MeetingSteps.start;
      if (config) {
        this.initialConfiguration = config;
      }
    },
    handleLeaveMeeting() {
      this.currentStep =
        this.currentStep === MeetingSteps.preparation
          ? MeetingSteps.welcome
          : MeetingSteps.closed;
    },
    updateBreadcrumbs() {
      const breadcrumbs = [
        {
          text:
            this.skipWelcome && !this.room?.isMain
              ? "calendar.breadcrumb-text"
              : "rooms.breadcrumb-text.rooms-list",
          to: {
            name:
              this.skipWelcome && !this.room?.isMain ? "calendar" : "rooms",
          },
        },
        {
          text: this.room?.name
            ? this.room?.name
            : "events.breadcrumb-text.event-details-room",
          active: true,
        },
      ];
      this.$store.commit("app/SET_BREADCRUMBS", breadcrumbs);
    },
    handleEdited() {
      setTimeout(() => {
        this.fetchData();
      }, 2000)
    },
  },
};
</script>
<style lang="scss">
@import "@/views/apps/rooms/scss/rooms.scss";
</style>
