<template>
  <b-alert
    v-if="connectionFailed"
    show
    variant="danger"
    class="mx-auto text-center"
    style="width: 300px"
    >{{ $t("rooms.error-message.no-media") }}</b-alert
  >
  <div v-else id="meetingSDKElement" style="justify-content: center"></div>
</template>

<script>
import ZoomMtgEmbedded from "@zoomus/websdk/embedded";
// import { mounted } from "vue-add-to-calendar";

export default {
  name: "ZoomMeeting",
  props: {
    room: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      client: "",
      sdkKey: "",
      role: 1,
      meetingNumber: 96311459938,
      userEmail: "",
      userName: "",
      registrantToken: "",
      connectionFailed: false,
      isSmall: false,
    };
  },
  computed: {
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    isStaff() {
      return this.$store.getters.currentCollective.isStaff;
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  async mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    await this.getSignature();
  },

  methods: {
    handleResize() {
      this.isSmall = window.innerWidth < 500;
    },
    async getSignature() {
      this.client = ZoomMtgEmbedded.createClient();
      this.sdkKey = "UZrQBhSL6wvTFV2zpMKZuIRaBJR9Ect9h7vV";
      this.meetingNumber = this.room?.identifier;
      this.userName = `${this.$store.getters.loggedMember.name} ${this.$store.getters.loggedMember.surname}`;
      this.userEmail = this.$store.getters.loggedMember.email;
      this.role = this.isStaff ? 1 : 0;
      const response = await this.$store.dispatch("postZoom", {
        meetingNumber: this.meetingNumber,
        role: this.role,
      });
      this.startMeeting(response.data);
    },
    startMeeting(signature) {
      try {
        this.client.init({
          debug: true,
          zoomAppRoot: meetingSDKElement,
          language: this.currentLocale === "en" ? "en-US" : "es-ES",
          customize: {
            // meetingInfo: ['topic', 'host', 'mn', 'pwd', 'telPwd', 'invite', 'participant', 'dc', 'enctype'],
            video: {
              isResizable: true,
              viewSizes: {
                default: {
                  width: this.isSmall ? 300 : 1000,
                  height: this.isSmall ? 400 : 600,
                },
                ribbon: {
                  width: this.isSmall ? 300 : 1000,
                  height: this.isSmall ? 400 : 600,
                },
              },
            },
          },
        });
      } catch {}
      this.client
        .join({
          sdkKey: this.sdkKey,
          signature,
          meetingNumber: this.meetingNumber,
          userName: this.userName,
          password: "",
        })
        .then(() => {})
        .catch(() => {
          // this.$emit('leave-meeting');
          this.connectionFailed = true;
        });

      this.client.on("connection-change", (payload) => {
        if (payload.state === "Closed") {
          this.$emit("leave-meeting");
        }
      });
    },
  },
};
</script>

<style scoped>
#meetingSDKElement {
  /* width: 80vw;
  height: 680px; */
  margin: auto;
  text-align: center;
}
</style>
